<!--
 * @Description: 初装回访率报表
 * @Author: ChenXueLin
 * @Date: 2022-03-10 09:38:24
 * @LastEditTime: 2022-05-27 14:06:38
 * @LastEditors: ChenXueLin
-->
<template>
  <div class="content-view-wrapper pd-10">
    <div
      class="content-view section-wrapper"
      ref="sectionWrapper"
      v-loading="loading"
      :element-loading-background="loadingBackground"
    >
      <!-- 搜索头部 start -->
      <table-title @resize="handleTableTitleResize">
        <template v-slot:searchForm>
          <el-form class="search-list" ref="searchForm" :model="searchForm">
            <el-form-item class="search-item--2" prop="currentMonth">
              <el-date-picker
                style="width:200px"
                v-model="searchForm.currentMonth"
                type="month"
                placeholder="选择月"
                value-format="timestamp"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item class="search-item--buttons">
              <el-button type="primary" @click="handleSearch">查询</el-button>
              <el-button class="reset" @click="handleReset">重置</el-button>
            </el-form-item>
          </el-form>
        </template>
        <template v-slot:operateAction>
          <i class="e6-icon-export_fill" title="导出" @click="handleExport"></i>
        </template>
      </table-title>
      <!-- 搜索头部 end -->
      <!-- 表格 start -->
      <section class="table-wrapper">
        <el-table
          border
          :height="resizeViewHeight + 'px'"
          :data="tableData"
          highlight-current-row
          class="elTable"
          ref="elTable"
        >
          <!-- 序号 start -->
          <el-table-column
            label="序号"
            width="50"
            fixed="left"
            header-align="center"
            align="center"
          >
            <template slot-scope="scope">
              <span>
                {{
                  scope.$index +
                    1 +
                    (searchForm.pageIndex - 1) * searchForm.pageSize
                }}
              </span>
            </template>
          </el-table-column>
          <!-- 序号 end -->
          <el-table-column
            show-overflow-tooltip
            v-for="(column, index) in columnData"
            :key="index"
            :prop="column.fieldName"
            :label="column.fieldLabel"
            :min-width="column.width"
            :fixed="column.fixed"
            :align="column.align"
            header-align="center"
          >
            <el-table-column
              v-for="(option, index) in column.optionsList"
              :key="index"
              :prop="option.fieldName"
              :label="option.fieldLabel"
              :min-width="option.width"
              :fixed="option.fixed"
              :align="option.align"
              header-align="center"
            >
            </el-table-column>
          </el-table-column>
        </el-table>
      </section>
      <!-- 表格 end -->

      <!-- 分页 start -->
      <section class="pagination-wrapper fixed-section" ref="paginationWrapper">
        <el-pagination
          :page-size.sync="searchForm.pageSize"
          :current-page.sync="searchForm.pageIndex"
          :page-sizes="pageSizes"
          :layout="layout"
          :total="total"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </section>
      <!-- 分页 end -->
    </div>
  </div>
</template>

<script>
import base from "@/mixins/base";
import listPage from "@/mixins/list-page";
import listPageReszie from "@/mixins/list-page-resize";
import { printError } from "@/utils/util";
import { getFirstVisiteRate, exportFirstVisiteRate } from "@/api";
import { exportData } from "@/utils/download";
export default {
  name: "maintainReport",
  data() {
    return {
      searchForm: {
        currentMonth: new Date().getTime(),
        pageIndex: 1,
        pageSize: 20
      },
      total: 0,
      loading: false,
      columnData: [
        {
          fieldName: "corpCount",
          display: true,
          fieldLabel: "新增客户数",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "revisitCount",
          display: true,
          fieldLabel: "回访数",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "revisitSuccessCount",
          display: true,
          fieldLabel: "回访成功数",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "revisitRate",
          display: true,
          fieldLabel: "回访率(%)",
          width: 140,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "revisitSuccessRate",
          display: true,
          fieldLabel: "回访成功率(%)",
          width: 140,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          display: true,
          fieldLabel: "请问您是否开始使用我司的平台进行车辆监控呢？",
          width: 140,
          disabled: false,
          fixed: false,
          align: "center",
          optionsList: [
            {
              fieldName: "serviceYes",
              display: true,
              fieldLabel: "有使用",
              width: 140,
              disabled: false,
              fixed: false,
              align: "center"
            },
            {
              fieldName: "serviceNo",
              display: true,
              fieldLabel: "未使用",
              width: 140,
              disabled: false,
              fixed: false,
              align: "center"
            },
            {
              fieldName: "serviceRate",
              display: true,
              fieldLabel: "使用率(%)",
              width: 140,
              disabled: false,
              fixed: false,
              align: "center"
            }
          ]
        },
        {
          display: true,
          fieldLabel: "您现在使用我司产品感觉满意吗？",
          width: 140,
          disabled: false,
          fixed: false,
          align: "center",
          optionsList: [
            {
              fieldName: "service2Ok",
              display: true,
              fieldLabel: "满意",
              width: 140,
              disabled: false,
              fixed: false,
              align: "center"
            },
            {
              fieldName: "service2YB",
              display: true,
              fieldLabel: "一般",
              width: 140,
              disabled: false,
              fixed: false,
              align: "center"
            },
            {
              fieldName: "service2No",
              display: true,
              fieldLabel: "不满意",
              width: 140,
              disabled: false,
              fixed: false,
              align: "center"
            },
            {
              fieldName: "service2Rate",
              display: true,
              fieldLabel: "满意率(%)",
              width: 140,
              disabled: false,
              fixed: false,
              align: "center"
            }
          ]
        },
        {
          display: true,
          fieldLabel:
            "请问当时我们工程师在现场完装完毕后，是否有将IC卡提供给您？",
          width: 140,
          disabled: false,
          fixed: false,
          align: "center",
          optionsList: [
            {
              fieldName: "service3Ok",
              display: true,
              fieldLabel: "有提供",
              width: 140,
              disabled: false,
              fixed: false,
              align: "center"
            },
            {
              fieldName: "service3BX",
              display: true,
              fieldLabel: "便携式设备",
              width: 140,
              disabled: false,
              fixed: false,
              align: "center"
            },
            {
              fieldName: "service3No",
              display: true,
              fieldLabel: "未提供",
              width: 140,
              disabled: false,
              fixed: false,
              align: "center"
            },
            {
              fieldName: "service3Rate",
              display: true,
              fieldLabel: "比例(%)",
              width: 140,
              disabled: false,
              fixed: false,
              align: "center"
            }
          ]
        },
        {
          display: true,
          fieldLabel: "请问是否有其他问题或建议呢？",
          width: 140,
          disabled: false,
          fixed: false,
          align: "center",
          optionsList: [
            {
              fieldName: "service4Yes",
              display: true,
              fieldLabel: "有",
              width: 140,
              disabled: false,
              fixed: false,
              align: "center"
            },
            {
              fieldName: "service4No",
              display: true,
              fieldLabel: "无",
              width: 140,
              disabled: false,
              fixed: false,
              align: "center"
            },
            {
              fieldName: "service4Rate",
              display: true,
              fieldLabel: "比例(%)",
              width: 140,
              disabled: false,
              fixed: false,
              align: "center"
            }
          ]
        }
      ], //表头
      tableData: [] // 表格数据
    };
  },
  mixins: [listPage, base, listPageReszie],
  computed: {},
  watch: {},
  created() {
    this.queryList();
  },
  mounted() {
    this.setTableScrollDOM("elTable");
  },
  activated() {
    this.setScrollRecord();
  },
  methods: {
    //查询全部任务单
    async queryList() {
      try {
        this.loading = true;
        let res = await getFirstVisiteRate(this.searchForm);
        this.tableData = this.getFreezeResponse(res, {
          path: "data"
        });
        this.total = this.tableData.length;
        this.$nextTick(() => {
          this.$refs.elTable.doLayout();
        });
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    // 导出
    handleExport() {
      exportData(this, exportFirstVisiteRate);
    }
  }
};
</script>
<style lang="scss" scoped></style>
